.btn-bg {
  background: #2a3042 !important;
  color: white !important;
  border: 1px solid #2a3042;
}
.eye_css {
  cursor: pointer;
}
.eye_css:hover {
  color: red;
}

.customeinput {
  width: 100%;
  height: 40px;
  background-color: white;
  box-shadow: 5px 5px 2px, 2px rgba(0, 0, 0, 0);
  margin-top: 5px;
  margin-bottom: 5px;
  border: 0;
  padding: 5px;
}

.select2-selection .css-13cymwt-control {
  background: black !important;
  border: 0;
}
